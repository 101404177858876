<template>
    <div class="banner" v-if="ach">
        <img :src="ach.banner.image" />
        <div class="banner__text">{{ ach.banner.title }}</div>
    </div>
    <div class="achbackground" v-if="ach">
        <div class="ach container">
            <div class="ach__title">{{ ach.banner.title }}</div>
            <div class="line1"></div>
            <div class="ach__award" v-for="item in ach.Achievements" :key="item">
                <div class="block">
                    <div class="ach__award__title">
                        <p>{{ item.title }}</p>
                    </div>
                    <div class="ach__award__desc1" v-html="item.description">
                    </div>
                    <div class="ach__award__image1" v-if="item.achievementImages.length == 1">
                        <div class="ach__award__image1__card" v-for="image1 in item.achievementImages" :key="image1">
                            <img :src="image1.image" />
                        </div>
                    </div>
                    <div class="ach__award__image2" v-if="item.achievementImages.length == 2">
                        <div class="ach__award__image2__card" v-for="image2 in item.achievementImages" :key="image2">
                            <img :src="image2.image" />
                        </div>
                    </div>
                    <div class="ach__award__image3" v-if="item.achievementImages.length > 2">
                        <div class="ach__award__image3__card" v-for="image3 in item.achievementImages" :key="image3">
                            <img :src="image3.image" />
                        </div>
                    </div>
                    <div class="ach__award__desc2" v-if="item.description2">
                        <p v-html="item.description2"></p>
                    </div>
                </div>
                <div class="line" v-if="!item.title"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { useHead } from "@vueuse/head"
export default {
    setup() {
        useHead({
            title: "Достижения",
            meta: [
                { hid: 'description', name: 'description', content: 'Описание страницы' },
                { hid: 'canonical', rel: 'canonical', href: 'https://hongqi-auto.kz/achievement'},
            ],
        })
    },
    data() {
        return {
            ach: false
        }
    },
    async created() {
        this.getPage()
    },
    methods: {
        async getPage() {
            await this.$axios.get(`/world/achievements?lang=ru`)
                .then(res => {
                    this.ach = res.data
                })
                .catch(err => {
                })
        },
    },
}
</script>
<style lang="scss" scoped>
.banner{
    position: relative;
    width: 100%;
    aspect-ratio: 2.7;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__text{
        position: absolute;
        color: rgb(255, 255, 255);
        font-size: min(max(18px, calc(1.125rem + ((1vw - 2.5px) * 3.2335))), 72px);
        top:50%;
        left: 15%;  
        font-weight: 700;
    }
}
.achbackground {
    background-color: #f8f8f8;
}

.line1 {
    border: 1.5px solid rgba(122, 111, 111, 0.534);
    width: 20%;
}

.line {
    border: 2px solid rgba(0, 0, 0, 0.534);
}

.block {
    display: flex;
    flex-direction: column;
    gap: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
    padding: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
    background: #fff;
    box-sizing: border-box;
    @media(max-width:867px) {
        padding-left: 10px;
        padding-right: 10px;
    }
}
.ach {
    display: flex;
    flex-direction: column;
    gap: min(max(35px, calc(2.1875rem + ((1vw - 7.68px) * 3.0382))), 70px);
    padding-top: min(max(35px, calc(2.1875rem + ((1vw - 7.68px) * 3.0382))), 70px);
    padding-bottom: min(max(35px, calc(2.1875rem + ((1vw - 7.68px) * 3.0382))), 70px);
    color: #282828;
    @media(max-width:867px) {
        gap: 20px;
        padding-top: 80px;
    }
    &__title {
        font-size:  min(max(24px, calc(1.5rem + ((1vw - 7.68px) * 1.3889))), 40px);
        font-weight: 700;
        @media(max-width:867px) {
            font-size: 24px;
        }
    }

    &__award {
        display: flex;
        flex-direction: column;
        gap:  min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 1.7361))), 50px);
        @media(max-width:867px) {
            gap: 20px;
        }
        &__title {
            font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 1.3889))), 30px);
            font-weight: 600;
        }
        &__image1{
            width: 100%;
            &__card{
                width: 100%;
                aspect-ratio: 2.50;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        &__image2 {
            width: 100%;
            display: flex;
            justify-content: space-between;
            &__card {
            width: 48%;
            aspect-ratio: 1.70;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    @media(max-width:867px) {
                        width: 80%;
                    }
                }
            }
        }

        &__image3 {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            row-gap: 20px;
            &__card {
            width: 49%;
            aspect-ratio: 1.70;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                @media(max-width:867px) {
                    width: 80%;
                }
            }
            }
        }
    }
}
</style>