<template>
    <div class="dilers">
        <div class="dilers__title">
            {{ $t('diler_1') }}
        </div>
        <div class="dilers__info">
            <div class="dilers__info__left">
                <div class="dilers__info__left__1"  v-for="item in dealer.addresses" :key="item.id" @click="changeCity(item.city), saveaddress(item.coordinates)" :class="{ 'selected': selectedCity === item.city }">
                    <p class="dilers__info__left__1__title">{{ item.name }}</p>
                    <div class="line"></div>
                    <div class="dilers__info__left__1__input">
                        <p class="dilers__info__left__1__input__title"> {{ $t('diler_2') }}</p>
                        <p class="dilers__info__left__1__input__subtitle">{{item.address}}</p>
                    </div>
                    <div class="dilers__info__left__1__input">
                        <p class="dilers__info__left__1__input__title"> {{ $t('diler_3') }}</p>
                        <div class="dilers__info__left__1__input__subtitle">
                            <div  v-for="time in item.worktime" :key="time">
                                {{ time }}
                            </div>
                        </div>
                    </div>
                    <div class="dilers__info__left__1__input">
                        <p class="dilers__info__left__1__input__title">ТЕЛ: </p>
                        <p class="dilers__info__left__1__input__subtitle" v-for="phone in item.phone" :key="phone">{{ phone }}</p>
                    </div>
                </div>
            </div>
            <div class="dilers__info__right" style="position: relative;">
                <div id="dilers-map-container" style="height:100%; width: 100%;"></div>
            </div>
        </div>
        <div class="dilers__bot">
            <div class="banner" v-if="dealer">
                <img :src="dealer.banner.image"/>
                <div class="banner__text">{{dealer.banner.title}}</div>
            </div>
        </div>
            </div>
</template>

<script>
import { useHead } from "@vueuse/head"
export default {
    setup() {
        useHead({
            title: "Дилеры",
            meta: [
                { hid: 'description', name: 'description', content: 'Описание страницы' },
                { hid: 'canonical', rel: 'canonical', href: 'https://hongqi-auto.kz/dealers'},
            ],
        })
    },
    data() {
        return {
            map: null,
            geocoder: null,
            marker: null,
            selectedCity: '',
            dealer: false,
            x: '',
            y: ''
        };
    },
    async created() {
        this.getPage();
    },
    mounted() {
        this.load2GISAPI().then(() => {
            this.initMap();
        });
    },
    methods: {
        async getPage() {
            await this.$axios
                .get(`dealer-addresses?url=hongqi-auto&lang=ru`)
                .then((res) => {
                    this.dealer = res.data;
                    this.x = res.data.addresses[0].coordinates.x;
                    this.y = res.data.addresses[0].coordinates.y;
                })
                .catch((err) => {});
        },
        load2GISAPI() {
            return new Promise((resolve) => {
                const script = document.createElement('script');
                script.src = 'https://maps.api.2gis.ru/2.0/loader.js?pkg=full';
                script.onload = resolve;
                document.head.appendChild(script);
            });
        },
        initMap() {
            const mapContainer = document.getElementById('dilers-map-container');
            DG.then(() => {
                this.map = DG.map(mapContainer, {
                    center: [this.x, this.y],
                    zoom: 16,
                });
                this.geocoder = DG.geocoding();
            });
        },
        setMarker(value) {
            let coordinates;
            coordinates = [value.x, value.y];
            if (this.map) {
                if (this.marker) {
                    this.marker.removeFrom(this.map);
                }
                this.map.setView(coordinates, 16);
                this.marker = DG.marker(coordinates).addTo(this.map);
            }
        },
        changeCity(city) {
            this.selectedCity = city;
        },
        saveaddress(value) {
            this.setMarker(value);
            this.x = value.x;
            this.y = value.y;
        },
    },
};
</script>
<style lang="scss" scoped>
.selected {
  border: 4px solid red !important;
}
.line{
    border: 1px solid black;
}
.banner{
    position: relative;
    width: 100%;
    aspect-ratio: 3.8;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
&__text{
    position: absolute;
    color: white;
    font-size: min(max(18px, calc(1.125rem + ((1vw - 2.5px) * 3.2335))), 72px);
    font-weight: 700;
    top:20%;
    left: 5%;  
}
}
    .dilers{
        display: flex;
        flex-direction: column;
        padding-top: 30px;
        &__title{
            text-align: center;
            font-size: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.0417))), 32px);
            color: #282828;
            padding-bottom: 30px;
            gap: 10px;
            text-transform: uppercase;
            @media(max-width:867px){
                    font-size: 24px;
                    padding-bottom: 20px;
                }
        }
        &__info{
            display: flex;
            padding: 10px min(max(40px, calc(2.5rem + ((1vw - 7.68px) * 3.4722))), 80px);
            justify-content: space-between;
            background: #f3f3f3;
            @media(max-width:867px){
                padding: 30px;
            }
            &__left{
                display: flex;
                flex-direction: column;
                gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
                width: 40%;
                overflow-y: auto;
                max-height: min(max(380px, calc(23.75rem + ((1vw - 7.68px) * 14.7569))), 550px);
                @media(max-width:867px){
                    width: 100%;
                }
                &__1{
                    display: flex;
                    flex-direction: column;
                    border: 4px solid white;
                    gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
                    padding: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px) min(max(16px, calc(1rem + ((1vw - 7.68px) * 1.3889))), 32px);
                    color: #282828;
                    background: white;
                    font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
                    cursor: pointer;
                    &__title{
                        @media(max-width:867px){
                            font-size: 20px;
                        }
                    }
                    &__input{
                        display: flex;
                        gap: 5px;
                        align-items: center;
                        &__title{
                            font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
                            font-weight: 600;
                            @media(max-width:867px){
                                font-size: 12px;
                                width: 40%;
                            }
                        }
                        &__subtitle{
                            font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
                            @media(max-width:867px){
                                font-size: 10px;
                                width: 55%;
                            }
                        }
                    }
                }
                &__2{
                    display: flex;
                    flex-direction: column;
                    border: 4px solid white;
                    gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
                    padding: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px) min(max(16px, calc(1rem + ((1vw - 7.68px) * 1.3889))), 32px);
                    color: #282828;
                    background: white;
                    font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
                    cursor: pointer;
                    &__title{
                        @media(max-width:867px){
                            font-size: 20px;
                        }
                    }
                }
            }
            &__right{
                width: 60%;
                @media(max-width:867px){
                    display: none;
                }
            }
        }
    }
</style>