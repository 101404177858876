<template>
    <div class="burgerbackground">
        <div class="burger">
            <router-link to="/model"><p>{{ $t('model') }}</p></router-link>
            <router-link to="/dealers"><p>{{ $t('head_1') }}</p></router-link>
            <router-link to="/owner"><p>{{ $t('owner') }}</p></router-link>
            <router-link to="/news"><p>{{ $t('head_2') }}</p></router-link>
            <router-link to="/world"><p>{{ $t('head_3') }}</p></router-link>
            <router-link to="/library"><p>{{ $t('head_9') }}</p></router-link>
            <div class="call">
                <img src="@/assets/icons/call5588.svg" @click="makeCall"/>
                <p @click="makeCall">5599</p>
            </div>
        </div>
        <img src="@/assets/icons/close.svg" class="close" @click="$store.state.openBurger = false"/>
    </div>
</template>
<script>
export default {
    methods: {
    makeCall() {
      window.location.href = 'tel:5599';
    }
  }
}
</script>
<style lang="scss" scoped>
    .burgerbackground{
        height: 100vh;
        width: 100%;
        background-image: url('@/assets/newImages/burgerbackground.webp');
        background-size: 100% 100%;
        object-fit: cover;
        position: fixed;
        z-index: 999;
    }
    .close{
        position: absolute;
        top: 20px;
        right: 20px;
    }
    .burger{
        padding: 150px 60px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        a{
            text-decoration: none;
            color: gray;
            font-size: 20px;
        }
    }
    .call{
        display: flex;
        gap: 10px;
        color: black;
    }
</style>