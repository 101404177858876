<template>
    <div class="contact">
        <div class="desctop">
        <img src="@/assets/newImages/contactback.webp" class="mainimg"/>
        </div>
        <div class="mobile"> 
            <img src="@/assets/newImages/contactbackmobile.webp" class="mainimg1"/>
        </div>
        <div class="contact__title container">
            {{ $t('contact_1') }}
        </div>
        <div class="contact__info container">
            <div class="contact__info__left">
                <img src="@/assets/newImages/infocar.webp"/>
            </div>
            <div class="contact__info__right">
                <p class="contant__info__right__title">ТОО "Allur Premium"</p>
                <div class="contact__info__right__address">
                    <p style="font-weight: 600;">{{ $t('contact_2') }}</p>
                    <p itemprop="address">{{ $t('contact_3') }}</p>
                </div>
                <div class="contact__info__right__email">
                    <p style="font-weight: 600;">{{ $t('contact_4') }}</p>
                    <p>Info.hongqi.ast@allur.kz</p>
                </div>
            </div>
        </div>
        <div class="contact__title container">
           {{ $t('contact_1') }}
        </div>
        <div class="contact__fill container">
            <div class="contact__fill__left">
                <div class="contact__fill__left__title">
                    {{ $t('contact_5') }}
                </div>
                <textarea placeholder="Оставьте ваше предлоение."></textarea>
                <div class="contact__fill__left__checkbox">
                    <input type="checkbox" v-model="v$.comment.$model"/>
                    <p>{{ $t('contact_6') }}</p>
                </div>
            </div>
            <div class="contact__fill__right">
                <div class="contact__fill__right__input" >
                    <p>{{ $t('contact_7') }}</p>
                    <input type="text" :placeholder="$t('call_3')" v-mask="'+7 (###) ###-##-##'" v-model="v$.phone.$model" @input="isNumber"/>
                    <template v-for="(error) of v$.phone.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="contact__fill__right__select">
                    <p>{{ $t('contact_9') }}</p>
                    <select v-model="v$.city.$model">
                        <option value="" disabled>{{ $t('contact_10') }}</option>
                        <option v-for="option in cities" :value="option.dealer_id">{{ option.name }}</option>
                    </select>
                    <template v-for="(error) of v$.city.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="contact__fill__right__input" style="width: 48%;">
                    <p>{{ $t('contact_12') }}</p>
                    <input type="text" :placeholder="$t('call_4')" v-model="v$.name.$model"/>
                    <template v-for="(error) of v$.name.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="contact__fill__right__input">
                    <p>{{ $t('contact_14') }}</p>
                    <input type="text" :placeholder="$t('contact_14')" v-model="v$.email.$model"/>
                    <template v-for="(error) of v$.email.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
            </div>
        </div>
        <button @click="sendData()" :disabled="isLoading">
        <div v-if="isLoading == true">
            <div class="loader">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
        </div>
        <div v-else>
            {{ $t('contact_15') }}
        </div>
        </button>
    </div>
</template>
<script>
import { mask } from "vue-the-mask";
import { ref } from "vue";
import axios from "axios";
import { useHead } from "@vueuse/head"
import { useVuelidate } from '@vuelidate/core'
import { toast } from 'vue3-toastify';
import { required, minLength, helpers, email, sameAs  } from "@vuelidate/validators";
import 'vue3-toastify/dist/index.css';
export default {
    setup() {
        const operators = ref([700, 701, 702, 705, 707, 708, 747, 771, 775, 776, 777, 778]);
        const isNumber = (e) => {
            let regex = /[0-9]/;
            if (!regex.test(e.key)) {
                e.preventDefault();
            }
            const fullNumber = e.target.value;
            const formattedNumber = fullNumber.replace(/[^0-9]/g, ''); // Удалить нецифровые символы
            if (formattedNumber === '7777777777') {
                e.target.value = "";
                toast.error('Такого сотового оператора не существует')
            }
            if (formattedNumber.length >= 4) { // Проверяем длину номера
                let operatorCode = parseInt(formattedNumber.slice(1, 4)); // Берем первые 3 цифры
                if (!operators.value.includes(operatorCode)) {
                    e.target.value = "";
                    toast.error('Такого сотового оператора не существует')
                }
            }
        };
        useHead({
            title: "Свяжитесь с нами",
            meta: [
                { hid: 'description', name: 'description', content: 'Описание страницы' },
                { hid: 'canonical', rel: 'canonical', href: 'https://hongqi-auto.kz/contactus'},
            ],
        });
        return { 
            v$: useVuelidate(),
            isNumber,
            operators
        };
    },
    directives: { mask },
    data(){
        return{
            isLoading: false,
            isSubmitClicked: true,
            city: '',
            cities: false,
            name: '',
            phone: '',
            email: '',
            comment: ''
        }
    },
    validations() {
    return {
      name: {
          required: helpers.withMessage('Обязательное поле', required),
      },
      phone: {
          required: helpers.withMessage('Обязательное поле', required),
          minLength: helpers.withMessage('Некорректный номер телефона', minLength(18))
      },
      email: {
          required: helpers.withMessage('Обязательное поле', required),
          email: helpers.withMessage('Некорректный email', email),
      },
      city: {
          required: helpers.withMessage('Обязательное поле', required),
      },
      comment: {
      },
    };
  },
    methods:{
        async getPage() {            
            await this.$axios.get(`https://admin.hongqi-auto.kz/api/V1/cities?lang=kz`)
            .then(res => {
                this.cities = res.data.cities
            })
            .catch(err => {
            })
        },
        resetModel(){
            this.name = '',
            this.comment = '',
            this.phone = '',
            this.city = '',
            this.email = ''
        },
        sendData(){
            this.v$.$validate();
            if (!this.v$.$invalid){
            this.isLoading = true;
            const back = {
              name: this.name,
              phone:  this.phone,
              dealer: this.city,
              model: '',
              comment: this.comment
            };
            axios
                    .post(
                      "https://admin.hongqi-auto.kz/api/V1/send-application",
                      back
                    )
                    .then((response) => {
                      if (response.status === 200) {
                        this.$store.state.openOk = true;
                        this.resetModel()
                        this.isLoading = false;
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                    })
                    .finally(() => {
                    });
        }
    }
    },
    mounted() {
        this.getPage();
    }
}
</script>

<style lang="scss" scoped>
.errorValid {
  font-size: 12px !important;
  color: #FF2A62 !important;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar {
  display: inline-block;
  width: 3px;
  height: 20px;
  background-color: rgba(255, 255, 255, .5);
  border-radius: 10px;
  animation: scale-up4 1s linear infinite;
}

.bar:nth-child(2) {
  height: 35px;
  margin: 0 5px;
  animation-delay: .25s;
}

.bar:nth-child(3) {
  animation-delay: .5s;
}

@keyframes scale-up4 {
  20% {
    background-color: #ffff;
    transform: scaleY(1.5);
  }

  40% {
    transform: scaleY(1);
  }
}
.has-error {
    input {
        border-color: #ff4f4f; /* Цвет границы при ошибке */
    }
    
    .error-message {
        color: #ff4f4f; /* Цвет текста ошибки */
        font-size: 14px;
        margin-top: 5px;
    }
}
.mainimg{
    width: 100%;
    @media(max-width:867px){
        height: 20vh;
        object-fit: cover;
    }
}
.mainimg1{
            width: 100%;
            object-fit: cover;
}
    .contact{
        display: flex;
        flex-direction: column;
        gap: 60px;
        padding-bottom: 100px;
        @media(max-width:867px){
            gap: 20px;
        }
        &__title{
            text-align: center;
            color: #282828;
            font-size: 40px;
            @media(max-width:867px){
                font-size: 24px;
    }
        }
        &__info{
            display: flex;
            justify-content: center;
            @media(max-width:867px){
                flex-direction: column;
    }
            &__left{
                width: 40%;
                height: 648px;
                @media(max-width:867px){
                    width: 100%;
                    height: fit-content;
    }
                img{
                    width: 100%;
                    height: inherit;
                    object-fit: cover;
                }
            }
            &__right{
                background: #F3F3F3;
                width: 30%;
                padding: 140px 40px;
                display: flex;
                flex-direction: column;
                gap: 40px;
                color: #282828;
                @media(max-width:867px){
                    width: 100%;
                    padding: 20px;
                    gap: 20px;
                }
                &__address{
                    display: flex;
                    gap: 10px;
                }
                &__email{
                    display: flex;
                    gap: 10px;
                }
            }
        }
        &__fill{
            display: flex;
            justify-content: space-between;
            @media(max-width:867px){
                flex-direction: column;
                gap: 20px;
                padding: 10px;
            }
            &__left{
                width: 45%;
                display: flex;
                flex-direction: column;
                @media(max-width:867px){
                    width: 100%;
                }
                &__title{
                    font-size: 20px;
                    color: #939393;
                    padding-bottom: 15px;
                    @media(max-width:867px){
                        font-size: 14px;
                     }
                }
                textarea{
                    height: 300px;
                    width: 100%;
                    resize: none;
                    outline: none;
                    padding: 15px;
                    background-color: #e9e9e9;
                    @media(max-width:867px){
                        width: 100%;
    }
                }
                &__checkbox{
                    display: flex;
                    padding-top: 30px;
                    align-items: center;
                    font-size: 18px;
                    gap: 10px;
                    @media(max-width:867px){
                        font-size: 12px;
                    }
                }
            }
            &__right{
                width: 45%;
                display: flex;
                flex-wrap: wrap;
                gap: 30px;
                @media(max-width:867px){
                    width: 100%;
                    font-size: 15px;
                }
                &__input{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    color: #8b8b8b;
                    font-size: 20px;
                    input{
                        height: 50px;
                        border-top: none; /* Убираем верхний бордер */
                        border-left: none; /* Убираем левый бордер */
                        border-right: none;
                        outline: none;
                        &::placeholder{
                            color: black;
                            font-size: 12px;
                        }
                    }
                }
                &__select{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    color: #8b8b8b;
                    font-size: 20px;
                    select{
                        appearance: none; /* Убираем стандартные стили браузера */
                        background: white;
                        color: black;
                        width: 100%;
                        padding: 15px 0px;
                        outline: none;
                        border-top: none; /* Убираем верхний бордер */
                        border-left: none; /* Убираем левый бордер */
                        border-right: none;
                        border-bottom: 1px solid black;
                    }
                }
            }
        }
        button{
            width: 20%;
            margin-right: auto;
            margin-left: auto;
            height: 60px;
            background-color: #cf1a1a;
            cursor: pointer;
            border: none;
            border-radius: 40px;
            color: white;
            @media(max-width:867px){
                width: 50%;
                height: 40px;
             }
        }
    }
</style>
