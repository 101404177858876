<template>
    <div class="owner">
        <div class="owner__banner">
            <img :src="owner.banner_image"/>
            <div class="owner__banner__text">{{ $t('owner') }}</div>
        </div>
        <div class="owner__titles">
            <p @click="activeId = 1" :class="{activeid: activeId == 1}">{{owner.title}}</p>
            <p @click="activeId = 2" :class="{activeid: activeId == 2}">{{owner.title2}}</p>
        </div>
        <div class="owner__block1 container" v-if="activeId == 1">
            <div class="owner__block1__left">
                <img src="@/assets/icons/owner1.png"/>
            </div>  
            <div class="owner__block1__right">
                <div class="owner__block1__right__top">
                    <p>{{ owner.description }}</p>
                </div>
                <div class="owner__block1__right__bot">
                    <p>{{ $t('owner_1') }}</p>
                    <div class="line"></div>
                    <div class="owner__block1__right__bot__buttons"> 
                        <a :href="owner.document" download style="text-decoration: none;"><button>
                            <img src="@/assets/icons/unload.svg"/>
                            <p>{{ $t('owner_2') }}</p>
                        </button> </a>
                        <a :href="owner.document" style="text-decoration: none;"><button>{{ $t('owner_3') }}</button></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="owner__block2 container" v-if="activeId == 2">
            <div class="owner__block2__in" v-for="item in models" :key="item">
                <img :src="item.image"/>
                <div class="owner__block2__in__top">
                    <p>{{ item.title }}</p>
                    <p style="font-size: 16px;">{{ $t('owner_4') }}</p>
                </div>
                <div class="line"></div>
                <div class="owner__block2__in__buttons">
                    <a :href="item.document" download style="text-decoration: none;"><button>
                            <img src="@/assets/icons/unload.svg"/>
                            <p>{{ $t('owner_2') }}</p>
                        </button> </a>
                        <a :href="item.document" style="text-decoration: none;"><button>{{ $t('owner_3') }}</button></a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useHead } from "@vueuse/head"
export default {
    setup() {
        useHead({
            title: "Владельцам",
            meta: [
                { hid: 'description', name: 'description', content: 'Описание страницы' },
                { hid: 'canonical', rel: 'canonical', href: 'https://hongqi-auto.kz'},
            ],
        })
    },
    async created() {
        this.getPage()
    },
    methods: {
        async getPage() {
            await this.$axios.get(`/for-staff?lang=ru`)
                .then(res => {
                    this.owner = res.data.forStaff[0]
                    this.models = res.data.models
                })
                .catch(err => {
                })
        },
    },
    data(){
        return{
            activeId: 1,
            owner: '',
            models: '',
            card: [
                {
                    name: 'H5',
                    img: 'img1'
                },
                {
                    name: 'H9',
                    img: 'img2'
                },
                {
                    name: 'H5',
                    img: 'img1'
                },
                {
                    name: 'HS5',
                    img: 'img4'
                },
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
    .activeid{
        color: #000000;
    }
    .line{
        border: 1px solid #000000;
    }
    .owner{
        display: flex;
        flex-direction: column;
        gap: 70px;
        padding-bottom: 80px;
        background: #FFFFFF;
        &__banner{
            position: relative;
            img{
                width: 100%;
                aspect-ratio: 2.7;
                object-fit: cover;
            }
        &__text{
            position: absolute;
            color: rgb(255, 255, 255);
            font-size: min(max(18px, calc(1.125rem + ((1vw - 2.5px) * 3.2335))), 72px);
            top:32%;
            left: 4%;  
            font-weight: 700;
        }
        }
        &__titles{
            font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 0.5208))), 24px);
            display: flex;
            justify-content: center;
            align-items: center;
            gap: min(max(50px, calc(3.125rem + ((1vw - 7.68px) * 4.3403))), 100px);
            color: #A2A2A2;
            p{
                cursor: pointer;
            }
        }
        &__block1{
            display: flex;
            justify-content: space-between;
            &__left{
                width: 50%;
                img{
                    width: 100%;
                    aspect-ratio: 1.26;
                }
            }
            &__right{
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 2.6042))), 60px) min(max(40px, calc(2.5rem + ((1vw - 7.68px) * 3.4722))), 80px) min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 2.6042))), 60px) min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
                &__top{
                    font-size:  min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.3472))), 18px);
                    line-height: 35px;
                }
                &__bot{
                    display: flex;
                    flex-direction: column;
                    gap:min(max(24px, calc(1.5rem + ((1vw - 7.68px) * 0.5208))), 30px);
                    font-size:  min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.3472))), 18px);
                    &__buttons{
                        display: flex;
                        gap: 40px;
                        a{
                            width: 30%;
                            @media(max-width:768px){
                                width: 100% !important;
                            }
                        }
                        button{
                            width: 100%;
                            display: flex;
                            gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
                            align-items: center;
                            padding: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px) 0px;
                            justify-content: center;
                            min-height: 75px;
                            font-size:  min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.5208))), 16px);
                            font-weight: 600;
                            background: transparent;
                            border: 2px solid #000000;
                            cursor: pointer;
                            &:hover{
                                color:white;
                                background: #000000;
                                img{
                                    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7476%) hue-rotate(110deg) brightness(98%) contrast(108%);
                                }
                            }
                        }
                    }
                }
            }
        }
        &__block2{
            display: flex;
            flex-wrap: wrap;
            gap: 1.33%;
            row-gap: min(max(45px, calc(2.8125rem + ((1vw - 7.68px) * 2.1701))), 70px);
            &__in{
                width: 32%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                &__top{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-weight: 500;
                    font-size:  min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 0.5208))), 24px);
                }
                img{
                    width: 100%;
                    aspect-ratio: 1.5;
                    object-fit: cover;
                }
                &__buttons{
                        display: flex;
                        gap: min(max(28px, calc(1.75rem + ((1vw - 7.68px) * 1.0417))), 40px);
                        justify-content: center;
                        img{
                            width: 22px;
                            height: 20px;
                        }
                        a{
                            width: 40%;
                            @media(max-width:768px){
                                width: 100% !important;
                            }
                        }
                        button{
                            width: 100%;
                            display: flex;
                            gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
                            align-items: center;
                            padding: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px) 0px;
                            justify-content: center;
                            min-height: 75px;
                            font-size:  min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.5208))), 16px);
                            font-weight: 600;
                            background: transparent;
                            border: 2px solid #000000;
                            cursor: pointer;
                            &:hover{
                                color:white;
                                background: #000000;
                                img{
                                    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7476%) hue-rotate(110deg) brightness(98%) contrast(108%);
                                }
                            }
                        }
                    }
            }
        }
        @media(max-width:768px){
            gap: 50px;
            padding-bottom: 40px;
            &__titles{
                font-size: 14px;
                flex-direction: column;
                gap: 30px;
            }
            &__block1{
                flex-direction: column;
                &__left{
                    width: 100%;
                }
                &__right{
                    width: 100%;
                    padding: 20px;
                    gap: 70px;
                    &__top{
                        font-size: 15px;
                    }
                    &__bot{
                        gap: 20px;
                        font-size: 15px;
                        &__buttons{
                            flex-direction: column;
                            margin-top: 20px;
                            gap: 20px;
                            button{
                                width: 100%;
                            }
                        }
                    }
                }
            }
            &__block2{
                flex-wrap: nowrap;
                flex-direction: column;
                &__in{
                    width: 90%;
                    margin: auto;
                    &__top{
                        font-size: 16px;
                    }
                    &__buttons{
                        flex-direction: column;
                        gap: 20px;
                        button{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
</style>