<template>
    <div class="test">
      <div class="test__title">{{ modalTitle }}</div>
      <div class="test__inputs">
        <div class="input">
          <input
            v-model="testData.name"
            id="nameInput"
            :placeholder="$t('call_4')"
            type="text"
          />
          <span class="error-message">{{ fieldErrors.name }}</span>
        </div>
        <div class="select">
          <select v-model="testData.model" id="dealerSelect">
            <option value="" disabled selected>Модель:</option>
            <option v-for="(item, idx) in models" :key="'d' + idx" :value="item">
              {{ item.name }}
            </option>
          </select>
          <span class="error-message">{{ fieldErrors.model }}</span>
        </div>
        <div class="input">
          <input
            v-model="testData.phone"
            v-mask="'+7 (###) ###-##-##'"
            id="phoneInput"
            :placeholder="$t('call_3')"
            type="text"
            @input="isNumber"
          />
          <span class="error-message">{{ fieldErrors.phone }}</span>
        </div>
        <div class="select">
          <select v-model="testData.city" id="citySelect">
            <option value="" disabled selected>{{ $t('td_1') }}</option>
            <option v-for="(item, idx) in cities" :key="'c' + idx" :value="item">
              {{ item.name }}
            </option>
          </select>
          <span class="error-message">{{ fieldErrors.city }}</span>
        </div>
      </div>
      <div class="test__bot">
        <input v-model="testData.agreement" type="checkbox" class="checkbox" style="width: 50px;"
        />
        <p class="test__bot__title">
          {{ $t('td_2') }}
        <br /><span style="color: black;">{{ $t('td_3') }}</span>
        </p>
  
        <span class="error-message">{{ fieldErrors.agreement }}</span>
      </div>
      <button id="submitButton" @click="sendData" :disabled="isLoading">
        <div class="loader" v-if="isLoading">
                          <span class="bar"></span>
                          <span class="bar"></span>
                          <span class="bar"></span>
                      </div>
            <div v-else>{{ $t('td_4') }}</div>
      </button>
    </div>
  </template>
  
  <script>
import { mask } from "vue-the-mask";
import { ref } from "vue";
import axios from "axios";
import { useHead } from "@vueuse/head"
import { useVuelidate } from '@vuelidate/core'
import { toast } from 'vue3-toastify';
import { required, minLength, helpers, email, sameAs  } from "@vuelidate/validators";
import 'vue3-toastify/dist/index.css';
  export default {
    setup() {
        const operators = ref([700, 701, 702, 705, 707, 708, 747, 771, 775, 776, 777, 778]);
        const isNumber = (e) => {
            let regex = /[0-9]/;
            if (!regex.test(e.key)) {
                e.preventDefault();
            }
            const fullNumber = e.target.value;
            const formattedNumber = fullNumber.replace(/[^0-9]/g, ''); // Удалить нецифровые символы
            if (formattedNumber === '7777777777') {
                e.target.value = "";
                toast.error('Такого сотового оператора не существует')
            }
            if (formattedNumber.length >= 4) { // Проверяем длину номера
                let operatorCode = parseInt(formattedNumber.slice(1, 4)); // Берем первые 3 цифры
                if (!operators.value.includes(operatorCode)) {
                    e.target.value = "";
                    toast.error('Такого сотового оператора не существует')
                }
            }
        };
        return { 
            v$: useVuelidate(),
            isNumber,
            operators
        };
    },
    props: {
      modalTitle: String, // Пропс для заголовка модального окна
    },
    directives: { mask },
    data: () => ({
      isLoading: false,
      currentPath: "",
      model: 1,
      newContactId: "",
      cities: false,
      testData: {
        name: null,
        model: "",
        city: "",
        phone: "",
        agreement: false,
      },
      requiredFields: ["name", "model", "city", "phone", "agreement"],
      fieldErrors: {},
      city: [
        {
          id: 1,
          name: "Алматы",
          route: 16
        },
        {
          id: 2,
          name: "Астана",
          route: 14
        },
      ],
      models: [
        {
          id: 1,
          name: "H5",
          route: 12640,
        },
        {
          id: 2,
          name: "HS5",
          route: 12642,
        },
        {
          id: 3,
          name: "H9",
          route: 12641,
        },
        {
          id: 4,
          name: "E-HS9",
          route: 12643,
        },
      ],
    }),
    created() {
      this.getPage()
    },
    methods: {
      async getPage() {            
            await this.$axios.get(`https://admin.hongqi-auto.kz/api/V1/cities?lang=kz`)
            .then(res => {
                this.cities = res.data.cities
            })
            .catch(err => {
            })
        },
   sendData() {
    this.fieldErrors = {};
    let hasErrors = false;
    for (const field of this.requiredFields) {
      if (!this.testData[field]) {
        this.fieldErrors[field] = "Обязательное поле";
        hasErrors = true;
      }
    }
    if (hasErrors) {
      this.isLoading = false; // Установка isLoading в false, если есть ошибки
      return;
    }
    this.isLoading = true; // Устанавливаем isLoading в true только если нет ошибок
    const formattedPhone = this.testData?.phone.replace(/\+7|\(|\)|-|\s/g, ''); 
    const back = {
      name: this.testData?.name,
      phone: formattedPhone,
      dealer: this.testData?.city?.dealer_id, // Исправлено: this.testData?.dealer?.route -> this.testData?.model?.route
      model: this.testData?.model?.route,
      type: 'model'
    };
    
    axios
      .post(
        "https://admin.hongqi-auto.kz/api/V1/send-application",
        back
      )
      .then((response) => {
        if (response.status === 200) {
          this.$store.state.openOk = true;
          this.isLoading = false;
        } else {
          console.log(response);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        this.testData.name = '';
        this.testData.model = ''; // Исправлено: this.testData.dealer = '' -> this.testData.model = ''
        this.testData.phone = '';
        this.testData.city = '';
        this.testData.agreement = false;
      });
  },
}
  };
  </script>
  
  <style lang="scss" scoped>
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bar {
    display: inline-block;
    width: 3px;
    height: 20px;
    background-color: rgba(255, 255, 255, .5);
    border-radius: 10px;
    animation: scale-up4 1s linear infinite;
  }
  
  .bar:nth-child(2) {
    height: 35px;
    margin: 0 5px;
    animation-delay: .25s;
  }
  
  .bar:nth-child(3) {
    animation-delay: .5s;
  }
  
  @keyframes scale-up4 {
    20% {
      background-color: #ffff;
      transform: scaleY(1.5);
    }
  
    40% {
      transform: scaleY(1);
    }
  }
  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .test {
    display: flex;
    flex-direction: column;
    gap: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 1.7361))), 50px);
    padding: 50px min(max(200px, calc(12.5rem + ((1vw - 7.68px) * 17.3611))), 400px);
    @media (max-width: 867px) {
      padding: 20px;
    }
    &__title {
      text-align: center;
      font-size: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
      color: #000000;
    }
    &__inputs {
      display: flex;
      flex-wrap: wrap;
      gap: 10%;
      row-gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
      .input {
        width: 45%;
        @media (max-width: 867px) {
          width: 95%;
          padding: 10px;
        }
  
        input {
          width: 100%;
          text-transform: uppercase;
          font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
          border: 1px solid black;
          padding: 0px min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
          height: min(max(40px, calc(2.5rem + ((1vw - 7.68px) * 3.4722))), 80px);
          outline: none;
          border-radius: 5px;
          &::placeholder {
            color: black;
            font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
          }
        }
      }
  
      .select {
        width: 45%;
        @media (max-width: 867px) {
          width: 95%;
          padding: 10px;
        }
        select {
          width: 100%;
          text-transform: uppercase;
          outline: none;
          padding: 0px min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
          height: min(max(40px, calc(2.5rem + ((1vw - 7.68px) * 3.4722))), 80px);
          border-radius: 5px;
          font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
          font-family: -apple-system, system-ui, Segoe UI, Roboto, Ubuntu,
            Cantarell, Noto Sans, sans-serif, STHeiti, Microsoft YaHei, Arial;
          border: 1px solid black;
          appearance: none;
          background: white;
          color: black;
        }
      }
    }
  
    &__bot {
      display: flex;
      gap: 20px;
      align-items: center;
      input{
            width: 20px;
            height: 20px;
        }
      &__title {
        font-size: 15px;
        color: #6d6e6f;
        @media (max-width: 867px) {
          font-size: 12px !important;
        }
      }
      @media (max-width: 867px) {
        font-size: 12px !important;
        align-items: start;
      }
    }
    button {
      width: 25%;
      padding: min(max(10px, calc(0.625rem + ((1vw - 10.24px) * 0.558))), 15px);
      background-color: #42373c;
      border-radius: 30px;
      border: none;
      color: white;
      margin-right: auto;
      margin-left: auto;
      cursor: pointer;
      @media (max-width: 867px) {
        width: 50%;
      }
    }
  }
  </style>
  