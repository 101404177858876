<template>
  <Header />
  <router-view class="main"/>
  <Footer />
</template>
<script>
import Header from '@/components/layouts/Header.vue'; 
import Footer from './components/layouts/Footer.vue';
export default{
    components:{
      Header,Footer
    },
}
</script>
<style lang="scss">
#app { 
width: 100vw; 
display: flex; 
flex-direction: column; 
align-items: stretch; 
} 
 *{
  box-sizing: border-box;
 }
html,body,p { 
  overflow-x: clip; 
  margin: 0; 
  padding: 0; 
  font-family: -apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,STHeiti,Microsoft YaHei,Arial;
} 
.main {
  flex-grow: 1;
}
</style>
