<template>
    <div class="library">
        <div class="desctop">
            <div class="banner" v-if="lib">
                <img :src="lib.banner.image"/>
                <div class="banner__text">{{lib.banner.title}}</div>
            </div>
            </div>
            <div class="mobile">
                <img src="@/assets/newImages/librarybackmobile.webp" class="mainimg1"/>
            </div>
        <div class="library__models">
            <div class="library__models__title"  v-for="item in lib.models" :key="item">
                <p @click="currentModel = item.id"  :class="{ active: currentModel == item.id }">{{ item.title }}</p>
            </div>
        </div>
        <div class="library__content container">
            <div class="library__content__model" v-for="item in lib.models" :key="item">
                <div class="library__content__model__in" v-if="currentModel == item.id">
                    <div class="libraries" v-for="lib in item.libraries" :key="lib">
                        <p class="title">{{ lib.title }}</p>
                        <div class="libraries__img" v-if="lib.type == 'image'">
                            <div class="libraries__img__cards" v-for="item in lib.items" :key="item">
                                <div class="openzoomback" v-if="selectedItem === item"  @click="closeZoom">
                                            <div class="openzoom"  @click.stop>
                                                <img :src="item.file" class="openzoomimage"/>
                                            </div>
                                            </div>
                                <div class="libraries__img__cards__card" :style="{ backgroundImage: 'url(' + item.file + ')' }">
                                    <div class="libraries__img__cards__card__content">
                                            {{ item.file_name }}
                                        <div>
                                            <a :href="item.file" download>
                                                <img class="download" src="@/assets/icons/download.svg" />
                                            </a>
                                            <img class="download" src="@/assets/icons/find.svg"  @click="selectItem(item)"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="libraries__img" v-if="lib.type == 'video'">
                            <div class="libraries__img__cards" v-for="item in lib.items" :key="item">
                                <div class="openzoomback" v-if="selectedItem === item"  @click="closeZoom">
                                    <div class="openzoom" @click.stop>
                                        <video playsinline controls autoplay class="openzoomvideo">
                                            <source :src="item.file" type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                                <div class="libraries__img__cards__card" :style="{ backgroundImage: 'url(' + item.cover_photo + ')' }">
                                    <div class="libraries__img__cards__card__content">
                                            {{ item.file_name }}
                                        <div>
                                            <a :href="item.file" download>
                                                <img class="download" src="@/assets/icons/download.svg" />
                                            </a>
                                            <img class="download" src="@/assets/icons/play.svg"  @click="selectItem(item)"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="libraries__img" v-if="lib.type == 'file'">
                            <div class="libraries__img__cards" v-for="item in lib.items" :key="item">
                                <div class="libraries__img__cards__card" :style="{ backgroundImage: 'url(' + item.cover_photo + ')' }">
                                    <div class="libraries__img__cards__card__content">
                                            {{ item.file_name }}
                                        <div>
                                            <a :href="item.file" download>
                                                <img class="download" src="@/assets/icons/download.svg" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useHead } from "@vueuse/head"
export default {
    setup() {
        useHead({
            title: "Библиотека",
            meta: [
                { hid: 'description', name: 'description', content: 'Описание страницы' },
                { hid: 'canonical', rel: 'canonical', href: 'https://hongqi-auto.kz/library'},
            ],
        })
    },
  data() {
    return {
      lib: false,
      currentModel: '',
      selectedItem: null, // Добавляем переменную selectedItem
    }
  },
  async created() {
    this.getPage()
  },
  methods: {
    closeZoom() {
      this.selectedItem = null; // Устанавливаем selectedItem в null для закрытия дивки
    },
    async getPage() {
      await this.$axios.get(`/libraries?lang=ru`)
        .then(res => {
          this.lib = res.data
          this.currentModel = res.data.models[0].id
        })
        .catch(err => {
        })
    },
    selectItem(item) {
      this.selectedItem = item; // Устанавливаем selectedItem на выбранный объект
    },
  },
}
</script>
<style lang="scss" scoped>
.banner{
    position: relative;
    img{
    width: 100%;
}&__text{
    position: absolute;
    color: white;
    font-size: min(max(18px, calc(1.125rem + ((1vw - 2.5px) * 3.2335))), 72px);
    top:30%;
    left: 10%;  
    font-weight: 700;
}
}
.openzoomback{
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.openzoomvideo{
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
}
.openzoom{
    width: 80vw;
    height: 70vh;
}
.openzoomimage{
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
}
.title{
    font-size: min(max(18px, calc(1.125rem + ((1vw - 10.24px) * 1.3393))), 30px);
}
.download{
    cursor: pointer;
    &:hover{
        filter: brightness(0) saturate(100%) invert(100%) sepia(7%) saturate(3%) hue-rotate(110deg) brightness(106%) contrast(100%);
        transition: all 0.3s ease;
        transform: scale(1.2);
    }
}
        .mainimg1{
            width: 100%;
            object-fit: cover;
        }
        .mainimg{
            width: 100%;
            object-fit: cover;
        }
.active{
    color: black;
    font-weight: 600;
}
    .library{
        display: flex;
        flex-direction: column;
        gap: 70px;
        @media(max-width:768px){
            gap: 30px;
        }
        &__models{
            display: flex;
            gap: min(max(20px, calc(1.25rem + ((1vw - 2.5px) * 9.5808))), 180px);
            justify-content: center;
            font-size: min(max(12px, calc(0.75rem + ((1vw - 2.5px) * 1.0778))), 30px);
            cursor: pointer;
            color: #a2a2a2;
            @media(max-width:768px){
                font-size: 16px;
            }
        }
    }
    .libraries{
            display: flex;
            flex-direction: column;
            gap: 70px;
            padding-top: 30px;
            padding-bottom: 30px;
            @media(max-width:768px){
                gap: 30px;
            }
            &__img{
                display: flex;
                flex-wrap: wrap;
                gap: 2%;
                row-gap: 30px;
                cursor: pointer;
                &__cards{
                    width: 32%;
                    background-color: rgba(0,0,0,.4) !important;
                    aspect-ratio: 1.5;
                    @media(max-width:768px){
                        width: 100%;
                    }
                    &__card{
                        width: 100%;
                        height: 100%;
                        box-sizing: border-box;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        z-index: -1;
                        &__content{
                            display: flex;
                            justify-content: space-between;
                            padding: 20px;
                            font-size: 23px;
                            align-items: center;
                            background-color: rgba(247, 238, 238, 0.582);
                            div{
                                display: flex;
                                gap: 10px;
                                img{
                                    width: 33px;
                                    height: 33px;
                                }
                            }
                        }
                    }
                }
            }
    }
</style>