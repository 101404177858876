import { createStore } from 'vuex'

export default createStore({
  state: {
    lang: localStorage.getItem("lang") || "ru",
    openCallBack:false,
    openOk:false,
    openBurger: false,
  },
  getters: {
    getLang: (state) => state.lang,
  },
  mutations: {
    SET_LANGUAGE(state, lang) { 
      localStorage.setItem("lang", lang); 
      state.lang = lang; 
    },
  },
  actions: {
  },
  modules: {
  }
})
