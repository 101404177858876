<template> 
    <div class="select" v-on-click-outside="closeSelectValues"> 
        <div class="select__back" @click="openValues = !openValues" :class="{activeBorder: openValues == true}"> 
            <p class="select__back-placeholder" :class="{'select__back-active': finalValue !== placeholderCustom}">{{ $t('lang') }}</p> 
        </div> 
        <transition name="bounce2"> 
            <div class="select__in"  v-show="openValues"> 
                <div class="select-bermuda"></div> 
                <div class="select__values"> 
                    <p v-for="(item, index) in data" :key="index" 
                    @click="selectThisValue(item.value, item.label), getLang(item.store)" 
                    :class="{ activeSelect: item.label == finalValue }">{{ item.label }}</p> 
                </div> 
            </div> 
        </transition> 
    </div> 
</template> 

<script> 
import { mapMutations } from 'vuex';
export default { 
    props: ['data', 'placeholderCustom'], 
    emit: ['vModelCustom'], 
    data() { 
        return {    
            openValues: false, 
            finalValue: this.placeholderCustom 
        } 
    }, 
    methods: { 
        ...mapMutations(["SET_LANGUAGE"]), 
        getLang(option) { 
          this.$i18n.locale = option; 
          this.SET_LANGUAGE(option.val); 
          this.$forceUpdate(); 
          location.reload(); 
        },
        closeSelectValues() { 
            this.openValues = false 
        }, 
        selectThisValue(valueClick, labelClick) { 
            this.finalValue = labelClick; 
            this.$emit('vModelCustom', valueClick) 
        } 
    }, 
    watch: { 
        $route() { 
            if (this.finalValue) { 
                this.openValues = false; 
            } 
        } 
    } 
} 
</script>
 
<style lang="scss" scoped> 
.activeSelect { 
    color: black; 
} 
.select { 
    position: relative; 
    &__back { 
        cursor: pointer; 
        display: flex; 
        justify-content: space-between; 
        border-radius: 10px; 
        
        img { 
            transition: all 0.3s ease; 
        } 
        &-placeholder { 
            font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.6944))), 18px);
            color: #666666; 
        } 
        &-active { 
            color: #666666; 
            @media(max-width:1024px)
            {
                color: white;
            }
        } 
    } 
    &__in { 
        position: absolute; 
        z-index: 2; 
        top: 110%; 
        display: flex; 
        flex-direction: column; 
        align-items: center; 
        justify-content: center; 
        width: 100%; 
    } 
    &-bermuda { 
        box-shadow: 0px 10px 20px 0px #0000001A; 
        width: 10px; 
        height: 5px; 
        background: white; 
        -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%); 
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%); 
    } 
    &__values { 
        right: 0; 
        left: 0; 
        display: flex; 
        flex-direction: column; 
        justify-content: space-between; 
        background: white; 
        border-radius: 10px; 
        overflow: hidden; 
        box-shadow: 0px 4px 15px 0px #0000001A; 
        p { 
            cursor: pointer; 
            padding: 10px 10px; 
            &:hover { 
                background: hsla(0, 0%, 0%, 0.04); 
            } 
        } 
    } 
} 
 
.bounce2-enter-active { 
  animation: bounce2-in 0.3s; 
} 
.bounce2-leave-active { 
  animation: bounce2-in 0.3s reverse; 
} 
 
@keyframes bounce2-in { 
  0% { 
    transform: rotateX(100deg) scale(0.9); 
    top: 70%; 
    opacity: 0; 
  } 
  100% { 
    top: 110%; 
    transform: rotateX(0) scale(1); 
    opacity: 1; 
  } 
} 
 
</style>