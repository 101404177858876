<template>
    <img src="@/assets/newImages/banner.webp"  class="mainimg"/>
    <div class="socialbackground">
    <div class="social container">
        <div class="social__title">
            <p>{{ $t('social') }}<br/>{{ $t('responsibility') }}</p>
        </div>
        <div class="line"></div>
        <div class="social__block1">
            <p>{{ $t('block1_1') }}</p>
            <img src="@/assets/newImages/block1img.webp"/>
            <p class="social__block1__title">{{ $t('block1_2') }}</p>
            <p>{{ $t('block1_3') }}</p>
            <img src="@/assets/newImages/block1img2.webp"/>
        </div>
        <div class="line"></div>
        <div class="social__block2">
            <div class="social__block2__title">
                <p>{{ $t('block2_1') }}</p>
            </div>
            <p>{{ $t('block2_2') }}</p>
            <div class="social__block2__cards">
                <div class="social__block2__cards__card" v-for="item in block2cards" :key="item">
                    <p class="social__block2__cards__card__year">{{ item.year }}</p>
                    <p class="social__block2__cards__card__text">{{ item.text }}</p>
                </div>
            </div>
            <div class="social__block2__title2">
                <p>{{ $t('block2_3') }}</p>
            </div>
            <p>{{ $t('block2_4') }}</p>
        </div>
        <div class="line"></div>
        <div class="social__block3">
            <div class="social__block3__title">
                <p>{{ $t('block3_1') }}</p>
            </div>
            <p>{{ $t('block3_2') }}</p>
            <div class="social__block3__cards">
                <div class="social__block3__cards__card" v-for="item in block3cards" :key="item">
                    <div class="social__block3__cards__card__top">
                        <p>{{ $t('block3_3') }}</p>
                        <p>{{ $t('block3_4') }}</p>
                    </div>
                    <div class="social__block3__cards__card__bot">
                        <img :src="require('@/assets/newImages/' + item.img + '.webp')"/>
                    <div class="social__block3__cards__card__bot__posibilities">
                        <div class="social__block3__cards__card__bot__posibilities__posibility" v-for="posibility in item.possibilities" :key="posibility">
                            <p>{{ posibility.name }}</p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { useHead } from "@vueuse/head"
export default {
    setup() {
        useHead({
            title: "Социальная ответсвенность",
            meta: [
                { hid: 'description', name: 'description', content: 'Описание страницы' },
                { hid: 'canonical', rel: 'canonical', href: 'https://hongqi-auto.kz/social'},
            ],
        })
    },
    data(){
        return{
            block2cards: [
                {
                    year: "2019",
                    text: this.$t('card1_1')
                },
                {
                    year: "2020",
                    text:  this.$t('card1_2')
                },
                {
                    year: "2030",
                    text:  this.$t('card1_3')
                },
                {
                    year: "2025",
                    text:  this.$t('card1_4')
                }

            ],
            block3cards: [
                {
                    img: "1",
                    possibilities:[
                        {
                            name: this.$t('p1_1')
                        },
                        {
                            name: this.$t('p1_2')
                        },
                        {
                            name: this.$t('p1_3')
                        },
                        {
                            name: this.$t('p1_4')
                        },
                    ]
                },
                {
                    img: "2",
                    possibilities:[
                    {
                            name: this.$t('p2_1')
                        },
                        {
                            name: this.$t('p2_2')
                        },
                        {
                            name: this.$t('p2_3')
                        },
                        {
                            name: this.$t('p2_4')
                        },
                    ]
                },
                {
                    img: "3",
                    possibilities:[
                    {
                            name: this.$t('p3_1')
                        },
                        {
                            name: this.$t('p3_2')
                        },
                        {
                            name: this.$t('p3_3')
                        },
                        {
                            name: this.$t('p3_4')
                        },
                    ]
                },
                {
                    img: "4",
                    possibilities:[
                    {
                            name: this.$t('p4_1')
                        },
                        {
                            name: this.$t('p4_2')
                        },
                        {
                            name: this.$t('p4_3')
                        },
                        {
                            name: this.$t('p4_4')
                        },
                    ]
                },
                {
                    img: "5",
                    possibilities:[
                    {
                            name: this.$t('p5_1')
                        },
                        {
                            name: this.$t('p5_2')
                        },
                        {
                            name: this.$t('p5_3')
                        },
                        {
                            name: this.$t('p5_4')
                        },
                    ]
                },
                {
                    img: "6",
                    possibilities:[
                    {
                            name: this.$t('p6_1')
                        },
                        {
                            name: this.$t('p6_2')
                        },
                        {
                            name: this.$t('p6_3')
                        },
                        {
                            name: this.$t('p6_4')
                        },
                        {
                            name: this.$t('p6_5')
                        },
                    ]
                },
                {
                    img: "1",
                    possibilities:[
                    {
                            name: this.$t('p7_1')
                        },
                        {
                            name: this.$t('p7_2')
                        },
                        {
                            name: this.$t('p7_3')
                        },
                    ]
                },

            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.socialbackground{
    background-color: #f8f8f8;
}
.line{
    border: 1.5px solid rgba(122, 111, 111, 0.534);
}
    .social{
        display: flex;
        flex-direction: column;
        gap: min(max(35px, calc(2.1875rem + ((1vw - 7.68px) * 3.0382))), 70px);
        padding-top: min(max(35px, calc(2.1875rem + ((1vw - 7.68px) * 3.0382))), 70px);
        padding-bottom: min(max(35px, calc(2.1875rem + ((1vw - 7.68px) * 3.0382))), 70px);
        color: #282828;
        padding-left: 10px;
        padding-right: 10px;
        @media(max-width:867px){
            padding-top: 75px;
            gap: 20px;
        }
        &__title{
            font-size: min(max(24px, calc(1.5rem + ((1vw - 7.68px) * 1.3889))), 40px);
            font-weight: 700;
            @media(max-width:867px){
            font-size: 24px;
        }
        }
        &__block1{
            display: flex;
            flex-direction: column;
            gap: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 1.7361))), 50px);
            font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
            @media(max-width:867px){
            font-size: 14px;
            gap: 20px;
        }
        &__title{
            font-size: min(max(21px, calc(1.3125rem + ((1vw - 7.68px) * 0.6076))), 28px);
            font-weight: 600;
        }
        }
        &__block2{
            display: flex;
            flex-direction: column;
            gap: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 1.7361))), 50px);
            @media(max-width:867px){
            gap: 20px;
        }
            &__title{
                font-size: min(max(27px, calc(1.6875rem + ((1vw - 7.68px) * 0.9549))), 38px);
                font-weight: 600;
                @media(max-width:867px){
                    font-size: 20px;
                }
            }
            &__cards{
                display: flex;
                flex-wrap: wrap;
                gap: 6%;
                row-gap: 30px;
                @media(max-width:867px){
                    gap: 20px;
                }
                &__card{
                    width: 43%;
                    background: white;
                    padding: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
                    height: fit-content;
                    border: 2px solid #d9d9d9;
                    border-radius: 30px;
                    display: flex;
                    flex-direction: column;
                    gap: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
                    @media(max-width:867px){
                        width: 100%;
                        padding: 10px;
                        gap: 10px;
                    }
                    &__year{
                        font-size: min(max(32px, calc(2rem + ((1vw - 7.68px) * 1.3889))), 48px);
                        color: #333333;
                        @media(max-width:867px){
                            font-size: 30px;
                        }
                    }
                    &__text{
                        font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
                        color: #333333;
                        line-height: 2;
                        @media(max-width:867px){
                            font-size: 12px;
                        }
                    }
                }
            }
            &__title2{
                font-size: min(max(21px, calc(1.3125rem + ((1vw - 7.68px) * 0.6076))), 28px);
                font-weight: 600;
            }
        }
        &__block3{
            display: flex;
            flex-direction: column;
            gap: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 1.7361))), 50px);
            @media(max-width:867px){
                gap: 20px;
            }
            &__title{
                font-size: min(max(24px, calc(1.5rem + ((1vw - 7.68px) * 1.3889))), 40px);
                font-weight: 700;
                @media(max-width:867px){
                    font-size: 24px;
                }
            }
            &__cards{
                 display: flex;
                 flex-wrap: wrap;
                 gap: 2%;
                 row-gap: 20px;
                 @media(max-width:867px){
                        gap: 20px;
                    }
                 &__card{
                    width: 49%;
                    display: flex;
                    flex-direction: column;
                    border: 2px solid #8fb641;
                    border-radius: 30px;
                    @media(max-width:1400px){
                        width: 100%;
                    }
                    @media(max-width:867px){
                        width: 100%;
                    }
                    
                    &__top{
                        display: flex;
                        justify-content: space-between;
                        padding: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
                        background-color: #8fb641;
                        border-radius: 30px 30px 0px 0px;
                        color: white;
                        @media(max-width:867px){
                            padding: 10px;
                            font-size: 14px;
                        }
                    }
                    &__bot{
                        display: flex;
                        padding: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
                        justify-content: space-between;
                        align-items: center;
                        @media(max-width:867px){
                            padding: 10px;
                        }
                        img{
                            width:35%;
                            object-fit: cover;
                        }
                        &__posibilities{
                            display: flex;
                            flex-direction: column;
                            gap: 15px;
                            width: 50%;
                            @media(max-width:867px){
                                width: 70%;
                            }
                            &__posibility{
                                padding: 15px;
                                border: 2px solid #8fb641;
                                border-radius: 30px;
                                color: #8fb641;
                                font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
                                @media(max-width:867px){
                                    font-size: 12px;
                                    padding: 5px;
                                }
                            }
                        }
                    }
                 }
            }
        }
    }
</style>