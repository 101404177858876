<template>
<div class="banner" v-if="history">
    <img :src="history.banner.image"/>
    <div class="banner__text">{{history.banner.title}}</div>
</div>
    <div class="history container" v-if="history">
        <div class="history__title">
            <p>{{history.banner.title}}</p>
        </div>
        <div class="history__cards">
            <div :class="{'cardreverse': index % 2 !== 0}" class="history__cards__card" v-for="(item,index) in history.history" :key="index">
                <div class="history__cards__card__img">
                    <img :src="item.image"/>
                </div>
                <div class="history__cards__card__info" :class="{'cardreverse': index % 2 !== 0}">
                    <p class="history__cards__card__info__year">{{ item.year }}</p>
                    <p style="font-size: 24px;">{{ item.title }}</p>
                    <p class="history__cards__card__info__text">{{ item.description}}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useHead } from "@vueuse/head"
export default {
    setup() {
        useHead({
            title: "История",
            meta: [
                { hid: 'description', name: 'description', content: 'Описание страницы' },
                { hid: 'canonical', rel: 'canonical', href: 'https://hongqi-auto.kz/history'},
            ],
        })
    },
    data(){
        return{
                history: false
        }
    },
    async created() {
        this.getPage()    
    },
    methods:{
        async getPage() {            
            await this.$axios.get(`/world/history?lang=ru`)
            .then(res => {
                this.history = res.data
            })
            .catch(err => {
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.banner{
    position: relative;
    width: 100%;
    aspect-ratio: 2.7;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
&__text{
    position: absolute;
    color: rgb(255, 255, 255);
    font-size: min(max(18px, calc(1.125rem + ((1vw - 2.5px) * 3.2335))), 72px);
    top:40%;
    left: 15%;  
    font-weight: 700;
}
}

.cardreverse{
    display: flex;
    flex-direction: row-reverse;
    text-align: start !important;
}
    .history{
        display: flex;
        flex-direction: column;
        gap: min(max(36px, calc(2.25rem + ((1vw - 10.24px) * 1.5625))), 50px);
        padding-top:  min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 1.7361))), 50px);
        padding-bottom:  min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 1.7361))), 50px);
        &__title{
            text-align: center;
            font-size: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
            color: #282828;
            font-weight: 500;
        }
        &__cards{
            display: flex;
            flex-direction: column;
            gap: min(max(36px, calc(2.25rem + ((1vw - 10.24px) * 1.5625))), 50px);
            &__card{
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media(max-width:867px){
                 flex-direction: column;
                }
                &__img{
                    width: 50%;
                    aspect-ratio: 2.03;
                    @media(max-width:867px){
                        width: 90%;
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                &__info{
                    width: 45%;
                    @media(max-width:867px){
                        width: 90%;
                    }
                    display: flex;
                    flex-direction: column;
                    gap: 40px;
                    text-align: end;
                    @media(max-width:867px){
                        gap: 20px;
                    }
                    &__year{
                        font-size: min(max(54px, calc(3.375rem + ((1vw - 10.24px) * 4.6875))), 96px);
                        color: #333333;
                        @media(max-width:867px){
                            font-size: 48px;
                        }
                        
                    }
                    &__text{
                        font-size: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.8929))), 20px);
                        color: #282828;
                        @media(max-width:867px){
                            font-size: 14px;
            }
                    }
                }
            }
        }
    }
</style>