<template>
    <div class="model">
    <div class="desctop" v-if="model">
        <div class="banner">
            <img :src="model.banner.image"/>
            <div class="banner__text">{{model.banner.title}}</div>
        </div>
    </div>
    <div class="mobile">
        <img src="@/assets/newImages/modelbackmobile.webp" class="mainimg1"/>
    </div>
        <p class="model__title">{{ $t('model') }}</p>
        <div class="model__cards">
            <div class="model__cards__card" v-for="item in model.models" :key="item">
                <div class="model__cards__card__title">
                    {{ item.title }}
                </div>
                <img :src="item.logo" @click="$router.push(`/models/${item.slug}`)"/>
                <button @click="$router.push(`/models/${item.slug}`)">{{ $t('home_3') }}</button>
            </div>
        </div>
    </div>
</template>
<script>
import { useHead } from "@vueuse/head"
export default {
    setup() {
        useHead({
            title: "Модели Hongqi",
            meta: [
                { hid: 'description', name: 'description', content: 'Описание страницы' },
                { hid: 'canonical', rel: 'canonical', href: 'https://hongqi-auto.kz/model'},
            ],
        })
    },
    data(){
        return{
            model: false
        }
    },
    async created() {
        this.getPage()
    },
    methods: {
    async getPage() {
      await this.$axios.get(`/models?lang=ru`)
        .then(res => {
          this.model = res.data
        })
        .catch(err => {
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.banner{
    position: relative;
    width: 100%;
    aspect-ratio: 2.7;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
&__text{
    position: absolute;
    color: rgba(56, 52, 73, 0.496);
    font-size: min(max(18px, calc(1.125rem + ((1vw - 2.5px) * 3.2335))), 72px);
    top:20%;
    left: 15%;  
    font-weight: 700;
}
}
    .model{
        display: flex;
        flex-direction: column;
        gap: min(max(40px, calc(2.5rem + ((1vw - 7.68px) * 3.4722))), 80px);
        padding-bottom: min(max(50px, calc(3.125rem + ((1vw - 7.68px) * 4.3403))), 100px);
        justify-content: center;
        align-items: center;
        @media(max-width:867px){
                   gap: 40px; 
        }
        .mainimg1{
            width: 100%;
            object-fit: cover;
        }
        &__title{
            text-align: center;
            font-size: min(max(32px, calc(2rem + ((1vw - 10.24px) * 3.125))), 60px);
            color: #282828;
            @media(max-width:867px){
            font-size: 30px;
          }
            
        }
        &__cards{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 5%;
            row-gap: min(max(80px, calc(5rem + ((1vw - 10.24px) * 2.2321))), 100px);
            @media(max-width:867px){
            flex-direction: column;
            gap: 60px;
          }
            &__card{
                width: 35%;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: min(max(60px, calc(3.75rem + ((1vw - 10.24px) * 4.9107))), 104px);
                font-weight: 600;
                color: #e3e3e3;
                gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
                @media(max-width:1024px){
                    width: 100%;
                    font-size: 45px;
                    gap: 10px;
                }
                img{
                    width: 80%;
                    aspect-ratio: 1.955;
                    object-fit: cover;
                    cursor: pointer;
                    @media(max-width:867px){
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
                button{
                    background-color: #484848;
                    cursor: pointer;
                    border-radius: 2vw;
                    color: #fff;
                    border: none;
                    padding: min(max(9px, calc(0.5625rem + ((1vw - 10.24px) * 0.6696))), 15px) min(max(45px, calc(2.8125rem + ((1vw - 10.24px) * 3.9063))), 80px);
                    text-transform: uppercase;
                    font-family: -apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,STHeiti,Microsoft YaHei,Arial;
                    transition: all 0.5s ease;
                    @media(max-width:867px){
                        border-radius: 30px;
                         }
                    &:hover{
                        background: red;
                    }
                }
            }
        }
    }
</style>