<template>
    <div class="banner" v-if="brand">
        <img :src="brand.banner.image"/>
        <div class="banner__text">{{brand.banner.title}}</div>
    </div>
    <div class="brandbackground" v-if="brand">
        <div class="brand container">
            <div class="brand__title">
                <p>{{brand.banner.title}}</p>
            </div>
            <div class="brand__1">
                <div class="brand__1__img">
                    <img :src="brand.brands[0].image"/>
                </div>
                <div class="brand__1__info">
                    <p class="brand__1__info__title">{{ brand.brands[0].title }}</p>
                    <p class="brand__1__info__sub">{{ brand.brands[0].description }}</p>
                </div>
            </div>
            <div class="brand__2">
                <div class="brand__2__info">
                    <p class="brand__2__info__title">{{brand.brands[1].title}}</p>
                    <p class="brand__2__info__sub">{{ brand.brands[1].description }}</p>
                </div>
                <div class="brand__2__img">
                    <img :src="brand.brands[1].image"/>
                </div>
            </div>
        </div>  
    </div>
</template>
<script>
import { useHead } from "@vueuse/head"
export default {
    setup() {
        useHead({
            title: "Брэнд",
            meta: [
                { hid: 'description', name: 'description', content: 'Описание страницы' },
                { hid: 'canonical', rel: 'canonical', href: 'https://hongqi-auto.kz/brand'},
            ],
        })
    },
    data(){
        return{
            brand: false
        }
    },
    async created() {
        this.getPage()    
    },
    methods:{
        async getPage() {            
            await this.$axios.get(`/world/brand?lang=ru`)
            .then(res => {
                this.brand = res.data
            })
            .catch(err => {
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.banner{
    position: relative;
    width: 100%;
    aspect-ratio: 2.7;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
&__text{
    position: absolute;
    color: rgb(255, 255, 255);
    font-size: min(max(18px, calc(1.125rem + ((1vw - 2.5px) * 3.2335))), 72px);
    top:50%;
    left: 15%;  
    font-weight: 700;
}
}
    .brandbackground{
        background-color: #e4e4e5;
        padding: 80px 0px;
    }
    .brand{
        display: flex;
        flex-direction: column;
        gap: min(max(36px, calc(2.25rem + ((1vw - 10.24px) * 1.5625))), 50px);
        &__title{
            font-size: min(max(40px, calc(2.5rem + ((1vw - 10.24px) * -1.7857))), 24px);
            color: #282828;
            font-weight: 700;
        }
        &__1{
            display: flex;
            @media(max-width:867px){
                flex-direction: column;
            }
            &__img{
                width: 50%;
                @media(max-width:867px){
                    width: 100%;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &__info{
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: min(max(36px, calc(2.25rem + ((1vw - 10.24px) * 2.6786))), 60px);
                color: #282828;
                padding: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
                background: white;
                
                @media(max-width:867px){
                width: 100%;
                padding: 20px;
                gap: 30px;
            }
                &__title{
                    font-size: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
                    @media(max-width:867px){
                font-size: 22px;
            }
                }
                &__sub{
                    font-size: min(max(16px, calc(1rem + ((1vw - 10.24px) * 0.8929))), 24px);
                    @media(max-width:867px){
                font-size: 15px;
            }
                }
            }
        }
        &__2{
            display: flex;
            @media(max-width:867px){
                flex-direction: column-reverse;
            }
            &__img{
                width: 50%;
                @media(max-width:867px){
                width: 100%;
            }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &__info{
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: min(max(36px, calc(2.25rem + ((1vw - 10.24px) * 2.6786))), 60px);
                color: #282828;
                padding: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
                background: white;
                box-sizing: border-box;
                @media(max-width:867px){
                width: 100%;
                padding: 20px;
                gap: 30px;
            }
                &__title{
                font-size: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
                    @media(max-width:867px){
                font-size: 22px;
            }
                }
                &__sub{
                    font-size: min(max(16px, calc(1rem + ((1vw - 10.24px) * 0.8929))), 24px);
                    @media(max-width:867px){
                font-size: 14px;
            }
                }
            }
        }
    }
</style>