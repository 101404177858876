<template>
    <div class="testbackground">
        <div class="test">
            <div class="test__title">
                ТЕСТ-ДРАЙВ
            </div>
            <div class="test__inputs">
                <div class="test__inputs__input">
                    <input v-model="testData.name" placeholder="Имя: *" type="text" :class="{ 'invalid': validationErrors.name }"/>
                    <p class="error-message">{{ validationErrors.name }}</p>
                </div>
                <div class="test__inputs__select">
                    <select v-model="testData.dealer" id="dealerSelect" :class="{ 'invalid': validationErrors.dealer }">
                        <option value="" disabled selected>Дилер: </option>
                        <option v-for="(item, idx) in newDealers" :key="'d' + idx" :value="item">{{ item.name }}</option>
                    </select>
                    <p class="error-message">{{ validationErrors.dealer }}</p>
                </div>
                <div class="test__inputs__input">
                    <input v-model="testData.phone" v-mask="'+7 (###) ###-##-##'" placeholder="Моб.Телефон: +7" type="text" :class="{ 'invalid': validationErrors.phone }" @input="isNumber"/>
                    <p class="error-message">{{ validationErrors.phone }}</p>
                </div>
                <div class="test__inputs__select">
                    <select v-model="testData.model" id="citySelect" :class="{ 'invalid': validationErrors.model }">
                        <option value="" disabled selected>Модель: </option>
                        <option v-for="(item,idx) in models" :key="'m'+ idx" :value="item">{{item.name}}</option>
                    </select>
                    <p class="error-message">{{ validationErrors.model }}</p>
                </div>
            </div>
                        <div class="test__bot">
                <input v-model="testData.agreement" type="checkbox" style="width: 30px; height: 30px;" :class="{ 'invalid': validationErrors.Agreement}"/>
                <p class="error-message">{{ validationErrors.agreement }}</p>
                <div class="text">
                    <p class="text__title">{{ $t('test_1') }}</p>
                    <p class="text__sub">{{ $t('test_2') }}</p>
                </div>
            </div>
            <button @click="sendData" :disabled="isLoading">
                <div v-if="isLoading">
                    <div class="loader">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            <div v-else class="callback__call">
                <p>{{ $t('test_3') }}</p>
            </div>
            </button>
        </div>
    </div>
</template>
<script>
import { mask } from "vue-the-mask";
import { ref } from "vue";
import axios from "axios";
import { useHead } from "@vueuse/head"
import { useVuelidate } from '@vuelidate/core'
import { toast } from 'vue3-toastify';
import { required, minLength, helpers, email, sameAs  } from "@vuelidate/validators";
import 'vue3-toastify/dist/index.css';
export default {
    setup() {
        useHead({
            title: "Тест",
            meta: [
                { hid: 'description', name: 'description', content: 'Описание страницы' },
                { hid: 'canonical', rel: 'canonical', href: 'https://hongqi-auto.kz/test'},
            ],
        })
        const operators = ref([700, 701, 702, 705, 707, 708, 747, 771, 775, 776, 777, 778]);
        const isNumber = (e) => {
            let regex = /[0-9]/;
            if (!regex.test(e.key)) {
                e.preventDefault();
            }
            const fullNumber = e.target.value;
            const formattedNumber = fullNumber.replace(/[^0-9]/g, ''); // Удалить нецифровые символы
            if (formattedNumber === '7777777777') {
                e.target.value = "";
                toast.error('Такого сотового оператора не существует')
            }
            if (formattedNumber.length >= 4) { // Проверяем длину номера
                let operatorCode = parseInt(formattedNumber.slice(1, 4)); // Берем первые 3 цифры
                if (!operators.value.includes(operatorCode)) {
                    e.target.value = "";
                    toast.error('Такого сотового оператора не существует')
                }
            }
        };
        return { 
            isNumber,
            operators
        };
    },
    directives: { mask },
    data(){
        return{
        newDealers: false,
        validationErrors: {
        name: '',
        dealer: '',
        phone: '',
        model: '',
        agreement: '',
    },
        isLoading: false,
        testData: {
            name: null,
            dealer: '',
            model: '',
            phone: '',
            agreement: false
        },
        models: [
            {
                id: 1,
                name: 'E-HS9',
                model: 'ehs9',
                route: 12643
            },
            {
                id: 2,
                name: 'HS5',
                model: "hs5",
                route: 12642
            },
            {
                id: 3,
                name: 'H5',
                model: "h5",
                route: 12640
                
            },
            {
                id: 4,
                name: 'H9',
                model: "h9",
                route: 12641
            }
        ],
        }
    },
    async created() {
        this.getPage()    
    },
    methods: {
        async getPage() {    
            await this.$axios.get(`dealers`)
            .then(res => {
                this.newDealers = res.data.dealers
            })
            .catch(err => {
            })  
        },
        sendData() {
         console.log('EFEF')   
        for (const key in this.validationErrors) {
            this.validationErrors[key] = '';
        }
        let isValid = true;
        
        if (!this.testData.name) {
            this.validationErrors.name = this.$t('test_4');
            isValid = false;
        }
        if (!this.testData.dealer) {
            this.validationErrors.dealer = this.$t('test_5');
            isValid = false;
        }
        if (!this.testData.phone || this.testData.phone.length !== 18) {
            this.validationErrors.phone = this.$t('test_6');
            isValid = false;
        }
        if (!this.testData.model) {
            this.validationErrors.model = this.$t('test_7');
            isValid = false;
        }
        if (!this.testData.agreement) {
            this.validationErrors.agreement = this.$t('test_8');
            isValid = false;
        }
        if (!isValid) {
            return; // Stop processing if validation failed
        }
         this.isLoading = true;   
         const formattedPhone = this.testData?.phone.replace(/\+7|\(|\)|-|\s/g, ''); 
         const back = {
              name: this.testData?.name,
              phone:  formattedPhone,
              dealer: this.testData?.dealer?.bitrix_id,
              model: this.testData?.model?.route,
              type: "buy"
            };
            axios
                    .post(
                      "https://admin.hongqi-auto.kz/api/V1/send-application",
                      back
                    )
                    .then((response) => {
                      if (response.status === 200) {
                        this.$store.state.openOk = true;
                        this.isLoading = false;
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                    })
                    .finally(() => {
                        this.testData.name = '';
                        this.testData.dealer = '';
                        this.testData.model = '';
                        this.testData.phone = '';
                        this.testData.agreement = false;
                    });
        }
    },
}
</script>
<style lang="scss" scoped>
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar {
  display: inline-block;
  width: 3px;
  height: 20px;
  background-color: rgba(255, 255, 255, .5);
  border-radius: 10px;
  animation: scale-up4 1s linear infinite;
}

.bar:nth-child(2) {
  height: 35px;
  margin: 0 5px;
  animation-delay: .25s;
}

.bar:nth-child(3) {
  animation-delay: .5s;
}

@keyframes scale-up4 {
  20% {
    background-color: #ffff;
    transform: scaleY(1.5);
  }

  40% {
    transform: scaleY(1);
  }
}
 .invalid {
        border-color: red; /* or any other visual indication */
    }
    .error-message {
        color: red;
        font-size: 12px;
        margin-top: 4px;
    }
.text {
    display: flex;
    flex-direction: column;

    &__title {
        text-transform: uppercase;
        color: #6d6e6f;
        font-size: 25px;

        @media(max-width:867px) {
            font-size: 10px;
        }
    }

    &__sub {
        font-size: 16px;

        @media(max-width:867px) {
            font-size: 8px;
        }
    }
}

.testbackground {
    background-image: url('@/assets/newImages/testback.webp');
    background-size: 100% 100%;
}

.test {
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    padding: 50px 250px;
    gap: 50px;

    @media(max-width:867px) {
        padding: 50px;
    }

    &__title {
        text-align: center;
        font-size: 40px;
        color: black;
    }

    &__inputs {
        display: flex;
        flex-wrap: wrap;
        gap: 120px;
        row-gap: 50px;

        @media(max-width:867px) {
            row-gap: 20px;
        }
        &__input{
            width: 43%;
        input {
            width: 100%;
            padding: 25px;
            outline: none;
            background: inherit;
            border: 1.5px solid black;

            @media(max-width:867px) {
                width: 100%;
            }

            &::placeholder {
                color: black;
                font-size: 15px;
            }
        }
    }
    &__select{
        width: 43.7%;
        select {
            width: 100%;
            padding: 25px;
            outline: none;
            background: inherit;
            border: 1.5px solid black;
            color: black;

            @media(max-width:867px) {
                width: 100%;
            }

            &::placeholder {
                color: black;
                font-size: 15px;
            }
        }
    }
    }

    &__bot {
        display: flex;
        gap: 20px;
        align-items: center;

        @media(max-width:867px) {
            align-items: start;
        }
    }

    button {
        width: 25%;
        padding: 15px;
        background-color: #42373c;
        border-radius: 30px;
        border: none;
        color: white;
        margin-right: auto;
        cursor: pointer;
        margin-left: auto;

        @media(max-width:867px) {
            width: 50%;
        }

        &:hover {
            transition: all 0.5s ease;
            background: red;
        }
    }
}
</style>