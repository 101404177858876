<template>
    <div class="card">
        <div class="card__title">
            <p>{{ $t('newsc') }}</p>
        </div>
        <div class="card__sub">
            {{article.title}}
        </div>
        <div class="line"></div>
        <div class="desctop">
        <div class="desc" v-html="descriptionWithoutStyle1"></div>
        </div>
    <div class="mobile">
        <div class="mobiledesc" v-html="descriptionWithoutStyle"></div>
    </div>
    </div>
    <testDriveForNews id="test" v-if="article.isForm === 1" :modalTitle="modalTitle"/>
</template>
<script>
import testDriveForNews from '@/components/models/testDriveForNews.vue';
import { useHead } from "@vueuse/head"
import { useRouter, useRoute } from 'vue-router'
export default {
    setup() {
        const route = useRoute()
        useHead({
            title: "Страница новости",
            meta: [
                { hid: 'description', name: 'description', content: 'Описание страницы' },
                { hid: 'canonical', rel: 'canonical', href: `https://hongqi-auto.kz/news/${route.params.slug}`},
            ],
        })
    },
    components:{
        testDriveForNews
    },
    computed: {
        descriptionWithoutStyle1() {
            if (this.article) {
            // Убираем атрибут style из тегов img
            return this.article?.description?.replace(/<img(.*?)style="(.*?)"(.*?)>/gi, '<img$1$3>');
            } else {
            return ''; // Или другое значение по умолчанию, если article не определено
            }
        },
        descriptionWithoutStyle() {
            if (this.article) {
            // Убираем атрибут style из тегов img
            return this.article?.description_mob?.replace(/<img(.*?)style="(.*?)"(.*?)>/gi, '<img$1$3>');
            } else {
            return ''; // Или другое значение по умолчанию, если article не определено
            }
        },
    },
    data(){
        return{
            article: [],
            modalTitle: 'Отправить заявку'
        }
    },
    async created() {
    await this.$axios.get(`article?lang=ru&slug=${this.$route.params.slug}`)
    .then(res => {
      this.article = res.data.article
    })
    .catch(err => {
    })
},
}
</script>
<style lang="scss" scoped>
.mobiledesc{
    width: 100%;
    img {
        width: 100%;
    }
}
.line{
    border: 1.5px solid rgba(122, 111, 111, 0.534);
}
    .card{
        display: flex;
        flex-direction: column;
        gap: 50px;
        padding: 0px min(max(100px, calc(6.25rem + ((1vw - 10.24px) * 33.4821))), 400px);
        padding-bottom: 50px;
        @media(max-width:867px){
            padding: 20px;
            margin-top: 20px;
            gap: 20px;
        }
        &__title{
            font-size: min(max(30px, calc(1.875rem + ((1vw - 10.24px) * 2.2321))), 50px);
            text-align: center;
            color: #282828;
            padding-top: 50px;
            @media(max-width:867px){
                font-size: 25px;
            }
        }
        &__sub{
            font-size: min(max(18px, calc(1.125rem + ((1vw - 10.24px) * 1.3393))), 30px);
            color: #282828;
            @media(max-width:867px){
                font-size: 20px;
            }
        }
    }
</style>