<template>
    <div class="callbackbackground" @click="$store.state.openCallBack = false">
        <div class="callback " @click.stop>
            <img src="@/assets/icons/logo.svg" />
            <div class="callback__title">
                <p>{{ $t('call_1') }}</p>
            </div>
            <div class="callback__sub">
                <p>{{ $t('call_2') }}</p>
            </div>
            <div class="callback__number">
                <p>{{ $t('call_3') }}</p>
                <input v-model="v$.phone.$model"  v-mask="'+7 (###) ###-##-##'" placeholder="+7 (000) 000-00-00" @input="isNumber">
                <template v-for="(error) of v$.phone.$errors" :key="error">
                  <p class="errorValid">{{ error.$message }}</p>
                </template>
            </div>
            <div class="callback__number">
                <p>{{ $t('call_4') }}</p>
                <input v-model="v$.name.$model" placeholder="Введите свое имя"/>
                <template v-for="(error) of v$.name.$errors" :key="error">
                  <p class="errorValid">{{ error.$message }}</p>
                </template>
            </div>
            <select v-model="v$.cityData.$model" class="select">
              <option value="" disabled selected>{{ $t('call_5') }}</option>
              <option v-for="(item) in cities" :key="item" :value="item">
                {{ item.name }}
              </option>
            </select>
            <template v-for="(error) of v$.cityData.$errors" :key="error">
                <p class="errorValid">{{ error.$message }}</p>
            </template>
            <button @click="sendData()">
                <div v-if="isLoading">
                    <div class="loader">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
                <div v-else class="callback__call">
                    <img src="@/assets/icons/call.svg" />
                    <p>{{ $t('call_6') }}</p>
                </div>
            </button>
            <div class="callback__bot">
                <p>{{ $t('call_7') }}</p>
                <p>{{ $t('call_8') }}</p>
            </div>
            <img src="@/assets/icons/close.svg" class="close" @click="$store.state.openCallBack = false" style="cursor: pointer;"/>
        </div>
    </div>
</template>
<script>
import { mask } from "vue-the-mask";
import { ref } from "vue";
import axios from "axios";
import { useHead } from "@vueuse/head"
import { useVuelidate } from '@vuelidate/core'
import { toast } from 'vue3-toastify';
import { required, minLength, helpers, email, sameAs  } from "@vuelidate/validators";
import 'vue3-toastify/dist/index.css';
export default {
  setup() {
        const operators = ref([700, 701, 702, 705, 707, 708, 747, 771, 775, 776, 777, 778]);
        const isNumber = (e) => {
            let regex = /[0-9]/;
            if (!regex.test(e.key)) {
                e.preventDefault();
            }
            const fullNumber = e.target.value;
            const formattedNumber = fullNumber.replace(/[^0-9]/g, ''); // Удалить нецифровые символы
            if (formattedNumber === '7777777777') {
                e.target.value = "";
                toast.error('Такого сотового оператора не существует')
            }
            if (formattedNumber.length >= 4) { // Проверяем длину номера
                let operatorCode = parseInt(formattedNumber.slice(1, 4)); // Берем первые 3 цифры
                if (!operators.value.includes(operatorCode)) {
                    e.target.value = "";
                    toast.error('Такого сотового оператора не существует')
                }
            }
        };
        return { 
            v$: useVuelidate(),
            isNumber,
            operators
        };
    },
    data() {
        return {
          phone: '',
          name: '',
          cityData: '',
          cities: false,
          isLoading: false,
          isButtonDisabled: true,
        };
    },
    validations() {
      return {
        name: {
            required: helpers.withMessage('Обязательное поле', required),
            minLength: helpers.withMessage('Слишком короткое имя', minLength(3)
            ),
        },
        phone: {
          required: helpers.withMessage('Обязательное поле', required),
          minLength: helpers.withMessage('Неккоректный номер телефона', minLength(18))
        },
        cityData: {
          required: helpers.withMessage('Обязательное поле', required),
        }
      };
  },
  created () {
        this.getPage()
    },
    directives: { mask },
    methods: {
      async getPage() {            
            await this.$axios.get(`https://admin.hongqi-auto.kz/api/V1/cities?lang=kz`)
            .then(res => {
                this.cities = res.data.cities
            })
            .catch(err => {
            })
        },
        
    sendData() {
      console.log('gala');
      
        this.v$.$validate();
        if (!this.v$.$invalid) {
        this.isLoading = true;
            const back = {
              name: this.name,
              phone: this.phone,
              dealer: this.cityData?.dealer_id,
              model: '',
              type: "buy"
            };
            axios
                .post("https://admin.hongqi-auto.kz/api/V1/send-application", back)
                    .then((response) => {
                      if (response.status === 200) {
                        this.$store.state.openOk = true;
                        this.isLoading = false;
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
        }
    },
    },
};
</script>
<style lang="scss" scoped>
.errorValid{
  color: red;
  font-size: 10px;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar {
  display: inline-block;
  width: 3px;
  height: 20px;
  background-color: rgba(255, 255, 255, .5);
  border-radius: 10px;
  animation: scale-up4 1s linear infinite;
}

.bar:nth-child(2) {
  height: 35px;
  margin: 0 5px;
  animation-delay: .25s;
}

.bar:nth-child(3) {
  animation-delay: .5s;
}

@keyframes scale-up4 {
  20% {
    background-color: #ffff;
    transform: scaleY(1.5);
  }

  40% {
    transform: scaleY(1);
  }
}
.has-error input {
    border-color: red;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}
.close{
    position: absolute;
    top: 20px;
    right: 20px;
    width: 25px !important;
    height: 25px !important;
}
.callbackbackground{
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    }
    .callback{
        padding: 30px;
        background: white;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: relative;
        &__call{
            display: flex;
            gap: 10px;
            align-items: center;
        }
        img{
          width: 50%;
          aspect-ratio: 3.27;
          object-fit: cover;
        }
        &__title{
            font-size: 25px;
            font-weight: 600;
        }
        &__sub{
            font-size: 17px;
        }
        select{
        outline: none;
        font-size: 12px;
        font-family: -apple-system, system-ui, Segoe UI, Roboto, Ubuntu,
          Cantarell, Noto Sans, sans-serif, STHeiti, Microsoft YaHei, Arial;
        border: 1px solid black;
        appearance: none;
        background: white;
        color: black;
        width: 100%;
          padding: 10px;
            background: rgba(236, 230, 230, 0.952);
            border-radius: 15px;
            outline: none;
            border: none;
        }
        &__number{
            display: flex;
            flex-direction: column;
            gap: 5px;
            font-size: 12px;
            padding: 10px;
            background: rgba(236, 230, 230, 0.952);
            border-radius: 15px;
            input{
                width: 100%;
                border: none;
                outline: none;
                background:  transparent;
            }
        }
        &__time{
            display: flex;
            gap: 10px;
            font-size: 10px;
            .line{
                border: 0.5px solid black;
            }
        }
        button{
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 10px;
            cursor: pointer;
                border: none;
                background: black;
                color: white;
                justify-content: center;
            img{
                width: 30px;
                height: 30px;
            }
        }
        &__bot{
            font-size: 14px;
        }
    }
</style>