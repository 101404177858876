import { createApp } from 'vue'
import { createHead } from "@vueuse/head"
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "./i18n";
import VueAwesomePaginate from "vue-awesome-paginate";
import axios from 'axios' 
// import { createHead } from '@unhead/vue'
const head = createHead()
const axiosInstance = axios.create({ 
    baseURL: 'https://admin.hongqi-auto.kz/api/V1/', 
    params: { 
      lang: store.getters.getLang || 'ru' 
    } 
}) 
const app = createApp(App)
.use(store)
.use(VueAwesomePaginate)
.use(i18n)
.use(router)
.use(head)

app.config.globalProperties.$axios = { ...axiosInstance } 
app.config.globalProperties.$cdn = 'https://admin.hongqi-auto.kz/'
app.mount('#app')
